import React, { useContext } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"

import { Layout, Seo } from "../components"
import Title from "../components/Title"

import { GatsbyContext } from "../context/context"

export const query = graphql`
  {
    allDatoCmsContact {
      edges {
        node {
          seo {
            description
            image {
              gatsbyImageData
            }
            title
          }
        }
      }
    }

    allDatoCmsPageIntro(filter: { title: { eq: "Contact Page " } }) {
      edges {
        node {
          id
          info
          title
        }
      }
    }
  }
`

const ContactPage = ({ data }) => {
  const {
    allDatoCmsContact: { edges: contact },
    allDatoCmsPageIntro: { edges: intro },
  } = data

  const { infoLinks } = useContext(GatsbyContext)

  return (
    <Layout>
      {contact.map(({ node }) => {
        const { title, description, image } = node.seo
        const seoImage = image.gatsbyImageData.images.fallback.src

        return <Seo title={title} description={description} image={seoImage} />
      })}
      <div className="section page-header">
        <Container>
          <SectionHeading>
            <Row>
              <Col>
                <Title title="Contact Us" />
              </Col>
            </Row>
          </SectionHeading>
        </Container>
      </div>

      <Wrapper className="section">
        <Container>
          <Row>
            <Col>
              {intro.map(({ node }) => {
                return <h3 className="page-intro">{node.info}</h3>
              })}
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <h3>Our Office</h3>

              <article>
                {infoLinks.map((item, index) => {
                  return (
                    <>
                      <InfoBox key={index}>
                        <div className="info__icon">{item.icon}</div>

                        <div className="info__info">
                          <p>{item.info}</p>
                        </div>
                      </InfoBox>
                    </>
                  )
                })}
              </article>
            </Col>

            <Col md={8}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.5232805937158!2d-73.0858668493227!3d44.81090318471486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cca1ac1aca0c013%3A0xd4ec187b16cb51c4!2s2%20N%20Main%20St%2C%20St%20Albans%20City%2C%20VT%2005478!5e0!3m2!1sen!2sus!4v1619151166081!5m2!1sen!2sus"
                width="600"
                height="450"
                style={{ border: "0" }}
                title="Kissane Associates"
                allowfullscreen=""
                loading="lazy"
                className="responsive-iframe"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;

  .section {
    position: relative;
    height: 0;
    overflow: hidden;
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;
    text-align: center;

    .responsive-iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
`

const InfoBox = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  position: relative;
  padding: 7px 0;

  .info__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      color: var(--clr-grey-4);
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;

    .info__info {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .info__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .info__icon svg {
    font-size: 1rem;
    color: var(--clr-primary-6);
  }
`

const SectionHeading = styled.section`
  @media screen and (max-width: 480px) {
    padding: 4rem 0 0 0;
  }

  h2.section-heading {
    text-align: center;
    color: var(--clr-primary-6);
    padding-top: 7rem;

    @media screen and (max-width: 480px) {
      padding-top: 2rem;
    }
  }
`

export default ContactPage
